<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <pull-to-refresh />
    <layout-loading v-if="!pageDidLoad" />

    <component v-show="pageDidLoad" :is="layout">
      <router-view :key="$route.fullPath" />
    </component>

<!--    <component v-show="pageDidLoad" :is="layout">-->
<!--      <keep-alive >-->
<!--        <router-view :key="$route.fullPath" v-if="$route.meta.keepAlive && $route.meta.isBackNavigation" />-->
<!--      </keep-alive>-->
<!--      <router-view :key="$route.fullPath" v-if="!$route.meta.keepAlive || !$route.meta.isBackNavigation" />-->
<!--    </component>-->

    <app-tour :steps="tourSteps" />

    <update-p-w-a />
    <install-p-w-a />
    <install-i-o-s-app />
    <install-android-app />

    <app-review-modal />

    <media-preview />
    <browser-notification />

    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'
import InstallPWA from '@/components/prompters/InstallPWA'
import InstallIOSApp from '@/components/prompters/InstallIOSApp'
import InstallAndroidApp from "@/components/prompters/InstallAndroidApp"
import UpdatePWA from '@/components/prompters/UpdatePWA'
import BrowserNotification from '@/components/notifications/BrowserNotification'
import MediaPreview from '@/components/modals/MediaPreview'
import AppReviewModal from "@/components/prompters/AppReviewModal"

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { ref, computed, watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'
import { pageLoadingState } from '@core/mixins/ui/pageLoadingState'
import { handleDOMNodeInserted } from '@core/mixins/ui/handleDOMNodeInserted'
import PullToRefresh from '@core/layouts/components/PullToRefresh'
import { nativeScrollBar } from '@core/mixins/ui/nativeScrollBar'
import AppTour from '@core/components/app-tour/AppTour'
import { userTour } from '@core/mixins/ui/user-tour'

const LayoutVertical = () => import('./layouts/vertical/LayoutVertical.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')
const LayoutLoading = () => import('./components/LayoutLoading')

export default {
  name: 'App',
  components: {
    PullToRefresh,
    // Layouts
    LayoutVertical,
    LayoutFull,
    LayoutLoading,
    InstallIOSApp,
    InstallAndroidApp,
    InstallPWA,
    UpdatePWA,
    AppReviewModal,
    MediaPreview,
    BrowserNotification,

    ScrollToTop,
    AppTour,
  },

  mixins: [pageLoadingState, handleDOMNodeInserted, nativeScrollBar, userTour],

  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      let defaultType = this.contentLayoutType

      if (this.$store.getters['auth/user'].loggedIn) {
        defaultType = 'vertical'
      }

      let type = this.$route.meta.layout ? this.$route.meta.layout : defaultType

      return `layout-${type}`
    },

    ready() {
      return this.$route.meta.layout != null
    },

    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  async beforeCreate() {
    // Set colors in theme
    const colors = [
      'primary',
      'secondary',
      'success',
      'info',
      'warning',
      'danger',
      'light',
      'dark',
    ]

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim()
    }

    if (_.isEmpty($themeBreakpoints)) {
      // Set Theme Breakpoints
      const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = breakpoints.length; i < len; i++) {
        $themeBreakpoints[breakpoints[i]] = Number(
          useCssVar(
            `--breakpoint-${breakpoints[i]}`,
            document.documentElement
          ).value.slice(0, -2)
        )
      }
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')

    let i18nextLng = localStorage.getItem('i18nextLng')

    if (i18nextLng == null || i18nextLng === '') {
      localStorage.setItem('i18nextLng', 'en')
    }
  },

  async mounted() {},

  data() {
    return {
      documentEventListener: true,
    }
  },

  metaInfo() {
    return {
      meta: this.$appMetaTags(),
    }
  },

  setup() {
    const { skin, skinClasses, enableScrollToTop } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    const { width: windowWidth } = useWindowSize()

    if (windowWidth.value) {
      // Set Window Width in store
      store.commit(
        'app/UPDATE_WINDOW_WIDTH',
        // document.documentElement.clientWidth
        windowWidth.value
      )
    }

    watch(windowWidth, (val) => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      windowWidth,
      skinClasses,
      enableScrollToTop,
    }
  },
}
</script>
