<!-- PWAPrompt.vue -->
<template>
  <b-toast
    id="install-android-app-toast"
    variant="primary"
    title="Download the Agendum App?"
    noAutoHide
    solid
    toaster="b-toaster-bottom-full"
    :visible="shown"
  >
    <div
      class="w-100 d-flex flex-sm-row flex-column justify-content-center align-items-center"
    >
      <div
        class="d-flex mr-0 mr-sm-1 mt-1 w-100"
        :class="googleOptions.wrapperCss"
        v-if="googleOptions.enabled"
      >
        <b-button
          variant="outline-dark"
          class="align-items-center btn btn-outline-dark d-flex p-0"
          type="button"
          :class="googleOptions.width"
          :disabled="loading"
          @click="installApp"
        >
          <img :src="googleLogo" class="px-sm-1 px-50" alt="Google logo" />
          <span
            class="align-items-center align-middle btn btn-block border-left-dark rounded-0 d-flex justify-content-around h-100 w-100 px-sm-1 px-50"
          >
            {{ googleOptions.text }}
          </span>
        </b-button>
      </div>

      <b-button
        class="mt-1 w-100"
        variant="outline-primary"
        type="button"
        :disabled="loading"
        @click="dismissPrompt"
      >
        No, thanks
      </b-button>
    </div>
  </b-toast>
</template>

<script>
import { BButton, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { formToastification } from '@core/mixins/ui/formToastification'
import { handleWebview } from '@core/mixins/ui/handleWebview'
import { mapGetters } from 'vuex'

export default {
  name: 'InstallAndroidApp',

  components: {
    BButton,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  mixins: [formToastification, handleWebview],

  data: () => ({
    shown: false,
    loading: false,
    isAppInstalled: false,

    defaultGoogleOptions: {
      enabled: true,
      text: 'Get it on Google Play',
      variant: 'primary',
      width: 'w-100',
      wrapperCss: 'justify-content-center',
      url: 'https://play.google.com/store/apps/details?id=com.agendum',
    },

    defaultAppleOptions: {
      enabled: true,
      text: 'Download on the App Store',
      variant: 'primary',
      width: 'w-100',
      wrapperCss: 'justify-content-center',
      url: 'https://apps.apple.com/ro/app/agendum-club/id6444837205',
    },

    googleLogo: require('@/assets/images/icons/google.svg'),
    appleLogo: require('@/assets/images/icons/apple.svg'),

    toastPosition: 'bottom-right',
    logo: require('@/assets/images/logo/logo.svg'),
  }),

  beforeMount() {
    let standalone = window.navigator.standalone,
      userAgent = window.navigator.userAgent.toLowerCase(),
      safari = /safari/.test(userAgent),
      ios = /iphone|ipod|ipad/.test(userAgent)

    if (
      process.env.NODE_ENV === 'production' &&
      !this.userDeclinedInstalling &&
      !this.isDesktopBreakpoint &&
      !ios &&
      !this.isWebview()
    ) {
      this.shown = true
    }
  },

  methods: {
    dismissPrompt() {
      this.shown = false
      this.declineInstalling()
    },

    declineInstalling() {
      this.$cookies.set('agendum_android_app', {
        install: {
          consent: false,
        },
      })
    },

    acceptInstalling() {
      this.$cookies.set('agendum_android_app', {
        install: {
          consent: true,
        },
      })
    },

    installApp() {
      this.loading = true
      this.dismissPrompt()
      window.open(this.googleOptions.url, '_blank').focus()
    },

    makeToast() {
      this.$bvToast.show('install-android-app-toast')
    },
  },

  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('app', ['currentBreakPoint']),

    isMobileBreakPoint() {
      return this.currentBreakPoint === 'sm' || this.currentBreakPoint === 'xs'
    },

    isDesktopBreakpoint() {
      return this.currentBreakPoint === 'lg' || this.currentBreakPoint === 'xl'
    },

    userDeclinedInstalling() {
      if (_.isEmpty(this.cookieApp)) {
        return false
      }

      return !this.cookieApp.install.consent
    },

    cookieApp() {
      return this.$cookies.get('agendum_android_app')
    },

    googleOptions() {
      return {
        ...this.defaultGoogleOptions,
      }
    },
    appleOptions() {
      return {
        ...this.defaultAppleOptions,
      }
    },
  },

  watch: {},
}
</script>

<style scoped></style>
