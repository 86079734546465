import Vue from 'vue'
import VueRouter from 'vue-router'
import checkMiddleware from '../utils/routerHelper'
import store from '../store'

// Routes
import pages from './routes/pages'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    // if a saved position is available, use it
    if (savedPosition) {
      return savedPosition
    } else {
      // otherwise scroll to top of the page
      return { top: 0 }
    }
  },
  routes: [
    ...pages,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

let routeHistoryStack = []
router.beforeEach((to, _, next) => {
  let middleware = []

  if (to.meta.middleware != null) {
    middleware = to.meta.middleware
  }

  return store.dispatch('auth/updateUser').then(() => {
    let response = checkMiddleware(middleware)

    if (store.getters['app/pageDidLoad']) {
      store.commit('app/TOGGLE_LOADING_ANIMATION', false)
      store.commit('app/TOGGLE_OVERLAY', false)
    }

    VueRouter.prototype.previousRoute = _

    if (!response.result) {
      let redirectRoute =
        response.redirect != null ? response.redirect : 'error-404'
      return next({ name: redirectRoute })
    }

    if (
      routeHistoryStack.length > 1 &&
      routeHistoryStack[routeHistoryStack.length - 2] === to.fullPath
    ) {
      // "Back" navigation
      to.meta.isBackNavigation = true
      routeHistoryStack.pop() // Remove last entry, as we are going back
    } else {
      // "New" navigation
      to.meta.isBackNavigation = false
      routeHistoryStack.push(to.fullPath) // Add the new path to the stack
    }

    return next()
  })
})

router.afterEach((to, from) => {})

export default router
